import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import { Box, Text, Card, Flex, Button } from 'theme-ui'
import { Stack, Main } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Section from '@components/Section'
import Seo from '@widgets/Seo'
import useSiteMetadata from '@helpers/useSiteMetadata'

/**
 * Shadow me to add your own content
 */

const styles = {
  imageWrapper: {
    borderRadius: `default`,
    overflow: `hidden`,
    position: `relative`
  },
  button: {
    display: [`none`, `block`],
    position: `absolute`,
    bottom: 4,
    right: 4
  },
  grid: {
    flexWrap: [`wrap`, `wrap`, `nowrap`],
    ' > div + div': {
      ml: [0, 0, 5]
    }
  },
  column: {
    flex: `auto`,
    flexBasis: [`full`, `full`, `1/2`]
  }
}

export default props => {
  const data = useStaticQuery(aboutQuery)
  const { topic } = useSiteMetadata()

  const { photo } = data
  const { fluid } = (photo && photo.childImageSharp) || {}

  return (
    <>
      <Seo title='About' />
      <Divider />
      <Stack>
        <Main>
          <PageTitle
            header='About Us'
            subheader={`Our expertise can help you buy the right ${topic} Franchise.`}
          />
          <Divider />
          <Box sx={styles.imageWrapper}>
            <Img fluid={fluid} />
            {/* <Button sx={styles.button}>Contact Us</Button> */}
          </Box>
          <Divider />
          <Flex sx={styles.grid}>
            <Box sx={styles.column}>
              <Section title={`Buy the Right ${topic} Franchise`}>
                <Text variant='p'>
                  If you are considering fulfilling your dream of owning a {topic} franchise, 
                  then you’ve come to the right place. We know how important this step is to 
                  you and your family, and we are here to help you with your search for the 
                  perfect {topic} franchise business.
                </Text>
              </Section>
              <Divider />
              <Section title='We Can Help'>
                <Text variant='p'>
                  Our expertise and relationships in the {topic} franchise industry 
                  can help you find the best opportunities.  More importantly, we get 
                  to know your strengths and needs to match you with the right franchises.
                </Text>
              </Section>
              <Divider />
              <Section title='Proven Process'>
                <Text variant='p'>
                  Like all successful businesses, we have a well-defined process in place. 
                  Our process will save you time, aggravation, and drastically streamlines 
                  your search.
                </Text>                           
                <Text variant='p'>
                  We are experts in the <strong>process</strong> of matching you with the 
                  best {topic} franchise opportunities:
                </Text>
              </Section>
            </Box>
            <Box sx={styles.column}>
              <Section title='How Can We Help You?'>
                <Card variant='paper'>
                  When you work with us, we’ll get you in front of the right 
                  franchises and the decision makers at the Franchise Companies who can 
                  answer your questions, and provide you with the information you need 
                  to make a smart decision.
                </Card>
              </Section>
              <Divider />
              <Section title='Next Steps'>
                <Card variant='paper'>
                  <ul>
                    <li>Step 1: Information Gathering</li>
                    <li>Step 2: Researching Your Options</li>
                    <li>Step 3: Discussing Your Potential Future Business</li>
                    <li>Step 4: Speaking with Your Future Franchise Partner (Franchisor)</li>
                  </ul>
                  
                  
                  <Button as={Link} to='/contact/'>
                    Get Started
                  </Button>
                  
                </Card>
              </Section>
            </Box>
          </Flex>
        </Main>
      </Stack>
    </>
  )
}

const aboutQuery = graphql`
  query MyAboutQuery {
    photo: file(absolutePath: { regex: "/about.(jpeg|jpg|gif|png)/" }) {
      childImageSharp {
        fluid(maxWidth: 1140, maxHeight: 500, cropFocus: NORTH) {
          ...GatsbyImageSharpFluid_noBase64
          width: presentationWidth
          height: presentationWidth
        }
      }
    }
  }
`
